import React, { createContext, useState } from "react";
export const Store = createContext();
const StoreContext = ({ children }) => {
  // let [baseUrl] = useState("http://localhost:7070/api/v1");
  let [baseUrl] = useState("https://royal-mines-backend.onrender.com/api/v1");
  // let [baseUrl] = useState("https://masjid.zumaconsults.com/api/v1");

  let states = {
    url: [baseUrl],
  };
  return <Store.Provider value={states}>{children}</Store.Provider>;
};
export default StoreContext;
