import { Icon } from "@iconify/react";
import React from "react";

function Category() {
  return (
    <>
      {/* <!-- Category Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="mb-3">Property Types</h1>
            <p>
              We prioritize our clients' needs, ensuring that we safeguard the
              essence of the initial concept and align it seamlessly with the
              right investment strategy.
            </p>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <section className="cat-item d-block bg-6eb5ff text-center rounded p-3">
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <Icon
                      className="img-fluid"
                      icon="material-symbols-light:apartment"
                      width="1.5rem"
                      height="1.5rem"
                    />
                  </div>
                  <h6>Block of Flats</h6>
                  <span>30+ Properties</span>
                </div>
              </section>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <section className="cat-item d-block bg-6eb5ff text-center rounded p-3">
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <Icon
                      className="img-fluid"
                      icon="material-symbols:villa"
                      width="1.5rem"
                      height="1.5rem"
                    />
                  </div>
                  <h6>Terrace</h6>
                  <span>20+ Properties</span>
                </div>
              </section>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <section className="cat-item d-block bg-6eb5ff text-center rounded p-3">
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <Icon
                      className="img-fluid"
                      icon="icon-park-outline:homestay"
                      width="1.5rem"
                      height="1.5rem"
                    />
                  </div>
                  <h6>Bungalows</h6>
                  <span>20+ Properties</span>
                </div>
              </section>
            </div>

            <div
              className="col-lg-3 col-sm-6 wow fadeInUp"
              data-wow-delay="0.7s"
            >
              <section className="cat-item d-block bg-6eb5ff text-center rounded p-3">
                <div className="rounded p-4">
                  <div className="icon mb-3">
                    <Icon
                      className="img-fluid"
                      icon="bxs:landscape"
                      width="1.5rem"
                      height="1.5rem"
                    />
                  </div>
                  <h6>Lands</h6>
                  <span>100+ Properties</span>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Category End --> */}
    </>
  );
}

export default Category;
