import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Store } from "../context/store";
import FadeLoader from "react-spinners/FadeLoader";
import apiRequest from "../utils/apiRequest";

function PropertyListing() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [propertyData, setProperies] = useState([]);
  let [loading, setLoading] = useState(false);
  let [, setError] = useState("");

  useEffect(() => {
    Promise.all([loadProperties()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  const loadProperties = async () => {
    setLoading(true);
    try {
      const url = `${baseUrl}/3properties`;
      const { data } = await apiRequest(url, "GET", null, null);
      setProperies(data.properties);
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <>
      {/*  Property List Start  */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-0 gx-5 align-items-end">
            <div className="col-lg-6">
              <div
                className="text-start mx-auto mb-5 wow slideInLeft"
                data-wow-delay="0.1s"
              >
                <h1 className="mb-3">Property Listing</h1>
                <p>
                  Discover your dream home with our comprehensive Property
                  Listing.
                </p>
              </div>
            </div>
          </div>

          <div className="tab-content">
            <div id="tab-1" className="tab-pane fade show p-0 active">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50vh",
                  }}
                >
                  <FadeLoader color="#0078e7" />
                </div>
              ) : (
                <div className="row g-4">
                  {propertyData?.length === 0 ? (
                    <div>
                      <h3 className="text-center">No Property Available.</h3>
                    </div>
                  ) : (
                    propertyData?.map((e, i) => {
                      return (
                        <>
                          <div
                            className="col-lg-4 col-md-6 wow fadeInUp"
                            data-wow-delay="0.1s"
                            key={i}
                          >
                            <Link to={`/property/${e.id}`}>
                              <div className="property-item rounded overflow-hidden">
                                <div className="position-relative overflow-hidden img-fluid-fixed">
                                  <img
                                    className="img-fluid"
                                    src={e.images[0]}
                                    alt=""
                                  />
                                  <div className="bg-pri rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                    {e.status}
                                  </div>
                                  <div className="bg-white rounded-top text-dark position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                    {e.propertyType}
                                  </div>
                                </div>
                                <div className="p-4 pb-0">
                                  <h5 className="text-dark mb-3">
                                    {" "}
                                    &#8358;{e.price.toLocaleString()}
                                  </h5>
                                  <Link
                                    to={`/property/${e.id}`}
                                    className="d-block h5 title"
                                  >
                                    {e.title}
                                  </Link>
                                  <p>
                                    <i className="fa fa-map-marker-alt text-dark me-2"></i>
                                    {e.address}
                                  </p>
                                </div>
                                <div className="d-flex border-top">
                                  <small className="flex-fill text-center border-end py-2">
                                    <i className="fa fa-ruler-combined text-dark me-2"></i>
                                    {e.propertySize}
                                  </small>
                                  <small className="flex-fill text-center border-end py-2">
                                    <i className="fa fa-bed text-dark me-2"></i>
                                    {e.bedrooms} Bedrooms
                                  </small>
                                  <small className="flex-fill text-center py-2">
                                    <i className="fa fa-bath text-dark me-2"></i>
                                    {e.toilet} Toilets
                                  </small>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })
                  )}
                </div>
              )}
            </div>

            <div
              className="wow fadeInUp"
              data-wow-delay="0.1s"
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Link to="/properties" className="btn btn-primary py-3 px-4 mt-5">
                See More
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/*  Property List End  */}
    </>
  );
}

export default PropertyListing;
