import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/images/logo.png";

function NavBar(props) {
  return (
    <>
      {/* Navbar Start  */}
      <div className="container-fluid nav-bar bg-transparent">
        <nav className="navbar navbar-expand-lg bg-white navbar-light py-0 px-4">
          <Link
            to="/"
            className="navbar-brand d-flex align-items-center text-center"
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "2px",
                marginRight: "4px",
              }}
            >
              <img
                className="me-2"
                src={img}
                alt="Royal Mines Logo"
                style={{ width: "50px", height: "50px" }}
              />
              <span
                style={{
                  textTransform: "uppercase",
                  color: "#0078e7",
                  fontWeight: 800,
                  lineHeight: "20px",
                }}
              >
                Royalmines <br /> Property
              </span>
            </div>
          </Link>
          <button
            type="button"
            className="navbar-toggler"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto">
              <Link to="/home" className={`nav-item nav-link ${props.home}`}>
                Home
              </Link>
              <Link to="/about" className={`nav-item nav-link ${props.about}`}>
                About
              </Link>
              {/* <div className="nav-item dropdown">
                <a
                  href="/"
                  className="nav-link dropdown-toggle"
                  data-bs-toggle="dropdown"
                >
                  Property
                </a>
                <div className="dropdown-menu rounded-0 m-0">
                  <Link to="/lists" className="dropdown-item">
                    {" "}
                    Property List
                  </Link>
                  <Link to="/types" className="dropdown-item">
                    {" "}
                    Property Type
                  </Link>
                  <Link to="/agents" className="dropdown-item">
                    {" "}
                    Property Agent
                  </Link>
                </div>
              </div> */}

              <Link
                to="/properties"
                className={`nav-item nav-link ${props.properties}`}
              >
                {" "}
                Properties
              </Link>

              <Link
                to="/contact"
                className={`nav-item nav-link ${props.contact}`}
              >
                {" "}
                Contact
              </Link>

              <Link
                to="/frequently-asked-questions"
                className={`nav-item nav-link ${props.faq}`}
              >
                {" "}
                FAQ
              </Link>
            </div>
          </div>
        </nav>
      </div>
      {/* Navbar End  */}
    </>
  );
}

export default NavBar;
