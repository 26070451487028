const faqData = [
  {
    q: "What types of properties do you specialize in?",
    a: "Our expertise includes Semi-Detached Terraces, Fully Detached Terraces, Block of Flats, and Bungalows.",
  },
  {
    q: "What financing options are available for purchasing a property?",
    a: "We offer various financing options, including Outright Purchase, Installment Payment, Mortgage (NHF or Rent-to-Own), and Retirement Savings Account (RSA).",
  },
  {
    q: "Do you provide assistance with mortgage pre-approval?",
    a: "Yes, we offer assistance with mortgage pre-approval.",
  },
  {
    q: "What types of mortgages are available?",
    a: "We provide National Housing Funds and Rent-to-Own mortgages.",
  },
  {
    q: "What documents are required to apply for a mortgage?",
    a: "To apply for a mortgage, you'll need the following documents: Employment and Confirmation letters, Bank Statements (most recent 1 year), Payslips (most recent 6 months), NHF Statement (updated), Work ID, NIN, Utility Bill (most recent), Passport, and Birth Certificate/Age Declaration.",
  },
  {
    q: "What documents are required to apply for a mortgage if I am self-employed?",
    a: "If you are self-employed, the required documents include: Activated Standing Order with evidence of deduction, Court Affidavit (indicating continued NHF contribution), CAC Registration, TIN, Means of ID, Bank Statements (most recent 1 year), Birth Certificate, NHF Passbook or Statement (updated), Passport, and Utility Bill (most recent).",
  },
  {
    q: "What is the maximum loan amount I can qualify for?",
    a: "The maximum loan amount you can qualify for is 50 million naira, subject to your income, age, and NHF contribution.",
  },
];

export default faqData;
