// apiRequest.js
const apiRequest = async (url, method, body = null, token = null) => {
  try {
    const headers = {
      "content-type": "application/json",
    };

    if (token) {
      headers["royal-client-token"] = token;
    }

    const options = {
      method,
      headers,
    };

    if (body) {
      options.body = JSON.stringify(body);
    }
    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`Failed to ${method} data`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`An error occurred during ${method}. Please try again.`);
  }
};

export default apiRequest;
