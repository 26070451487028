import React, { useContext, useState } from "react";
import NavBar from "../components/Nav";
import BackTop from "../components/BackToTop";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import img from "../assets/images/location.png";
import { Store } from "../context/store";
import ClipLoader from "react-spinners/ClipLoader";
import apiRequest from "../utils/apiRequest";
import Toast from "react-bootstrap/Toast";

function Contact() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [type] = useState("message");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const handleIframeLoad = () => {
    setIframeLoaded(true);
  };

  const sendMessage = async () => {
    setLoading(true);
    const trimmedName = name.trim();
    const trimmedEmail = email.trim();
    const trimmedPhone = phone.trim();
    const trimmedMessage = message.trim();

    // Validate fields before making the API call
    const validationErrors = [];
    if (!trimmedName) {
      validationErrors.push("Name is required. ");
    }
    if (!trimmedEmail || !/\S+@\S+\.\S+/.test(trimmedEmail)) {
      validationErrors.push("Please enter a valid email address. ");
    }
    if (!trimmedPhone) {
      validationErrors.push("Phone number is required. ");
    }
    if (!trimmedMessage || trimmedMessage.length < 11) {
      validationErrors.push("Message must be at least 11 characters long.");
    }

    if (validationErrors.length > 0) {
      setLoading(false); // Reset loading state on validation failure
      setError(validationErrors); // Set error message with all errors combined
      setShow(true);
      return; // Exit the function early to prevent API call
    }
    try {
      const url = `${baseUrl}/appointment`;
      const body = { name, email, phone, message, type };
      const data = await apiRequest(url, "POST", body, null);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setError(data.message);
      setShow(true);
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div classNameName="container-fluid px-3" style={{ background: "white" }}>
        <title>Contact RoyalMines Property</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar contact={"active"} />
        <BackTop />

        {/* Toast section */}
        <div className="toast-container">
          <Toast
            onClose={() => setShow(false)}
            show={show}
            delay={6000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">{error}</strong>
            </Toast.Header>
          </Toast>
        </div>

        {/* <!-- Contact Start --> */}
        <div className="container-xxl py-5" style={{ background: "white" }}>
          <div className="container">
            <div className="contact-header text-center">
              <div
                className="mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px", margin: "0 auto" }}
              >
                <h1 className="mb-3">Contact Us</h1>
                <p>
                  Welcome to our Contact Us page! We're delighted that you're
                  interested in reaching out to us. We look forward to hearing
                  from you and assisting you in any way we can!
                </p>
              </div>
            </div>

            <div className="row g-4">
              <div className="col-12">
                <div className="row gy-4">
                  <div className="row gy-4">
                    <div
                      className="col-md-6 col-lg-4 wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <div className="bg-6eb5ff rounded p-3 d-flex flex-column h-100">
                        <div
                          className="d-flex align-items-center bg-white rounded p-3 flex-grow-1"
                          style={{ border: "1px dashed rgba(15,46,79, .8)" }}
                        >
                          <div
                            className="icon me-3"
                            style={{ width: "45px", height: "45px" }}
                          >
                            <i className="fa fa-map-marker-alt text-primary"></i>
                          </div>
                          <span>
                            Plot 251/252 Herbert Macaulay way Millennium Plaza
                            Central Business District Opposite NNPC Towers.
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4 wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <div className="bg-6eb5ff rounded p-3 d-flex flex-column h-100">
                        <div
                          className="d-flex align-items-center bg-white rounded p-3 flex-grow-1"
                          style={{ border: "1px dashed rgba(15,46,79, .8)" }}
                        >
                          <div
                            className="icon me-3"
                            style={{ width: "45px", height: "45px" }}
                          >
                            <i className="fa fa-envelope-open text-primary"></i>
                          </div>
                          <span>info@royalminesproperty.com</span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-md-6 col-lg-4 wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <div className="bg-6eb5ff rounded p-3 d-flex flex-column h-100">
                        <div
                          className="d-flex align-items-center bg-white rounded p-3 flex-grow-1"
                          style={{ border: "1px dashed rgba(15,46,79, .8)" }}
                        >
                          <div
                            className="icon me-3"
                            style={{ width: "45px", height: "45px" }}
                          >
                            <i className="fa fa-phone-alt text-primary"></i>
                          </div>
                          <a href="tel:+2349040799419">
                            <span>+234 904 079 9419</span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p className="text-center">
                    <b>
                      Reach out to our dedicated office desk during our business
                      hours, from 9am to 5pm WAT, for personalized assistance
                      and expert guidance on all your inquiries.
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-delay="0.1s">
                {!iframeLoaded && (
                  <img
                    src={img}
                    alt="Royalmines property location"
                    style={{ maxWidth: "inherit" }}
                  />
                )}
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.9184595480395!2d7.500543615212516!3d9.058560193426446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x104de894e015dcdb%3A0x4c16b3b7dcfde02!2sMillennium%20Builders%20Plaza!5e0!3m2!1sen!2sng!4v1648449214373!5m2!1sen!2sng"
                  frameBorder="0"
                  style={{ minHeight: "400px", border: "0" }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                  title="Royalmines property location"
                  onLoad={handleIframeLoad}
                ></iframe>
              </div>

              <div className="col-md-6">
                <div className="wow fadeInUp" data-wow-delay="0.5s">
                  <div>
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            placeholder="Your Name"
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label for="name">Your Name</label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-floating">
                          <input
                            type="tel"
                            className="form-control"
                            id="phone"
                            placeholder="Your Phone Number"
                            required
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          />
                          <label for="phone">Your Phone Number</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            placeholder="Your Email"
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label for="email">Your Email</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <textarea
                            className="form-control"
                            placeholder="Leave a message here"
                            id="message"
                            required
                            style={{ height: "150px" }}
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                          ></textarea>
                          <label for="message">Message</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <button
                          className="btn btn-primary w-100 py-3"
                          onClick={sendMessage}
                          disabled={loading}
                        >
                          {loading ? (
                            <ClipLoader size={20} color={"#fff"} />
                          ) : (
                            "Send Message"
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Contact End --> */}

        <div>
          <Footer />
        </div>
      </div>
      <GoToTop />
    </>
  );
}

export default Contact;
