import React, { useContext, useState } from "react";
import img1 from "../assets/images/caller.avif";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Store } from "../context/store";
import ClipLoader from "react-spinners/ClipLoader";
import apiRequest from "../utils/apiRequest";
import Toast from "react-bootstrap/Toast";
import { Col, Row } from "react-bootstrap";

function Call() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [name, setName] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const type = "appointment";
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  const tomorrowFormatted = tomorrow.toISOString().split("T")[0];

  const bookAppointment = async () => {
    setLoading(true);
    const trimmedName = name.trim();
    const trimmedPhone = phone.trim();

    // Validate fields before making the API call
    const validationErrors = [];
    if (!trimmedName) {
      validationErrors.push("Name is required. ");
    }
    if (!fromDate || !toDate) {
      validationErrors.push(
        "Please select a date range for your appointment. This helps us schedule a time that works for both you and our office."
      );
    }
    if (!trimmedPhone) {
      validationErrors.push("Phone number is required. ");
    }

    if (validationErrors.length > 0) {
      setLoading(false); // Reset loading state on validation failure
      setError(validationErrors); // Set error message with all errors combined
      setShow1(true);
      return; // Exit the function early to prevent API call
    }
    try {
      const url = `${baseUrl}/appointment`;
      const body = { name, fromDate, toDate, phone, message, type };
      const data = await apiRequest(url, "POST", body, null);
      setName("");
      setFromDate("");
      setToDate("");
      setPhone("");
      setMessage("");
      setError(data.message);
      setShow1(true);
      setShow(false);
      handleClose();
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      {/* Toast section */}
      <div className="toast-container">
        <Toast
          onClose={() => setShow1(false)}
          show={show1}
          delay={4000}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{error}</strong>
          </Toast.Header>
        </Toast>
      </div>

      {/* <!-- Call to Action Start --> */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="bg-6eb5ff rounded p-3">
            <div
              className="bg-white rounded p-4"
              style={{ border: "1px dashed rgba(0, 0, 0, .3)" }}
            >
              <div className="row g-5 align-items-center">
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                  <img
                    className="img-fluid rounded w-100"
                    src={img1}
                    alt="customer support"
                  />
                </div>
                <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                  <div className="mb-4">
                    <h1 className="mb-3">Connect With Us</h1>
                    <p>
                      Reach out to our dedicated office desk during our business
                      hours, from 9am to 5pm WAT, for personalized assistance
                      and expert guidance on all your inquiries.
                    </p>
                  </div>
                  <div
                    style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}
                  >
                    <a href="tel:+2349040799419">
                      <button
                        id="no-outline"
                        type="tel"
                        className="btn btn-primary py-3 px-4 me-2"
                      >
                        <i className="fa fa-phone-alt me-2"></i>Make A Call
                      </button>
                    </a>

                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://wa.me/+2349040799419?text=I'm%20interested%20in%20inquiring%20about%20properties%20from%20your%20website"
                    >
                      <button
                        id="no-outline"
                        type="tel"
                        className="btn btn-primary py-3 px-4 me-2"
                      >
                        <i className="fab fa-whatsapp me-2"></i>Whatsapp
                      </button>{" "}
                    </a>
                    <button
                      id="no-outline"
                      className="btn btn-primary py-3 px-4"
                      onClick={handleShow}
                    >
                      <i className="fa fa-calendar-alt me-2"></i>Get Site
                      Visitation Appoinment
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Call to Action End --> */}

      {/* modal */}
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title
            className="text-primary"
            style={{ textAlign: "center", width: "100%" }}
          >
            Book Site Appoinment
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Full Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Enter your Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FloatingLabel>

            <FloatingLabel controlId="floatingPhone" label="Phone Number">
              <Form.Control
                type="tel"
                placeholder="Phone Number"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </FloatingLabel>

            <Row className="justify-content-between">
              <Col md={6}>
                <Form.Group controlId="formStartDate" className="my-3">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    min={tomorrowFormatted}
                    required
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formEndDate" className="my-3">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    min={tomorrowFormatted}
                    required
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <FloatingLabel controlId="floatingTextarea2" label="Message">
              <Form.Control
                as="textarea"
                placeholder="Leave a message here"
                style={{ height: "100px" }}
                required
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FloatingLabel>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={bookAppointment}
            disabled={loading}
            style={{minWidth:"150px"}}
          >
            {loading ? (
              <ClipLoader size={20} color={"#fff"} />
            ) : (
              "Book Appoinment"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Call;
