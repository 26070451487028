import React from "react";
import NavBar from "../components/Nav";
import Banner from "../components/Banner";
import BackTop from "../components/BackToTop";
import Category from "../components/Category";
import Service from "../components/ServiceOffered";
import PropertyListing from "../components/Properties";
import Call from "../components/Call";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";

function Home() {
  return (
    <>
      <div classNameName="container-fluid px-3">
        <title>RoyalMines Property</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar home={"active"} />
        <Banner />
        <BackTop />

        {/* mission */}
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInDown pt-5"
            data-wow-delay="0.1s"
            style={{ maxWidth: "600px" }}
          >
            <h1 className="mb-3">Our Mission</h1>
            <p>
              Our mission is to lead Nigeria's real estate and mortgage sector
              with trust and technology, delivering seamless solutions and
              empowering dreams through comprehensive real estate solutions.
            </p>
          </div>
        </div>

        <div style={{ background: "whitesmoke" }}>
          <Category />
          <Service />
        </div>

        <div style={{ background: "white" }}>
          <PropertyListing />
          <Call />
        </div>

        <div>
          <Footer />
        </div>
      </div>
      <GoToTop />
    </>
  );
}

export default Home;
