import React from "react";
// import { Link } from "react-router-dom";
import img1 from "../assets/images/banner1.jpeg";
import img2 from "../assets/images/banner2.jpeg";
import img3 from "../assets/images/banner3.jpeg";

function Banner() {
  return (
    <>
      {/* Header Start */}
      <div className="container-fluid header bg-white p-0">
        <div className="row g-0 align-items-center flex-column-reverse flex-md-row">
          <div className="col-md-6 p-5 mt-lg-5">
            <h1 className="display-5 animated fadeIn mb-4">
              Let Your <span className="text-primary">Family's Happiness </span>
              Begin in Your <span className="text-primary">Perfect Home</span>
            </h1>
            <p className="animated fadeIn mb-4 pb-2">
              Embark on your family's journey to happiness by investing in a
              home that mirrors your dreams. Establish a steadfast foundation
              for both your family's joy and your investment aspirations with
              the ideal property.
            </p>
          </div>

          <div
            id="carouselExampleSlidesOnly"
            className="carousel slide col-md-6"
            data-bs-ride="carousel"
          >
            <div className="carousel-inner">
              <div className="carousel-item active">
                <img
                  src={img1}
                  // className="d-block w-100"
                  id="carouselImg"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={img2}
                  // className="d-block w-100"
                  id="carouselImg"
                  alt="..."
                />
              </div>
              <div className="carousel-item">
                <img
                  src={img3}
                  // className="d-block w-100"
                  id="carouselImg"
                  alt="..."
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
      {/* Header End */}
    </>
  );
}

export default Banner;
