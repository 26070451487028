import React from "react";
import NavBar from "../components/Nav";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import BackTop from "../components/BackToTop";
import faqData from "../assets/js/faq";
import Call from "../components/Call";

function Faq() {
  return (
    <>
      <div className="container-fluid px-3" style={{ background: "white",paddingBottom:"10px" }}>
        <title>Frequently Asked Questions RoyalMines Property</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar faq={"active"} />
        <BackTop />

        <div className="container">
          <div className="faq wow fadeInUp" data-wow-delay="0.1s">
            <div>
              <h1 className="my-3">Frequently asked questions</h1>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                {faqData.map((e, i) => {
                  return (
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id={`flush-headingOne-${i}`}
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapseOne-${i}`}
                          aria-expanded="false"
                          aria-controls={`flush-collapseOne-${i}`}
                        >
                          {e.q}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapseOne-${i}`}
                        className="accordion-collapse collapse wow fadeInLeft"
                        data-wow-delay="0.2s"
                        aria-labelledby={`flush-headingOne-${i}`}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{e.a}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div
            className="faq"
            style={{ background: "#d8e6fd", borderRadius: "10px" }}
          >
            <h2 className="mx-4 my-2">
              Still have questions? Feel free to contact us for assistance.
            </h2>
            <Call />
          </div>
        </div>
      </div>
      
      <div>
        <Footer />
      </div>
      <GoToTop />
    </>
  );
}

export default Faq;
