import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/Nav";
import BackTop from "../components/BackToTop";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import { Link } from "react-router-dom";
import apiRequest from "../utils/apiRequest";
import { Store } from "../context/store";
import FadeLoader from "react-spinners/FadeLoader";
import Pagination from "react-bootstrap/Pagination";
import { useSearchParams } from "react-router-dom";

function Properties() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let [propertyData, setProperies] = useState([]);
  let [loading, setLoading] = useState(false);
  let [, setError] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const [totalPages, setTotalPages] = useState(1);
  const page = parseInt(searchParams.get("page")) || 1;

  useEffect(() => {
    Promise.all([loadProperties()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [page]);

  const loadProperties = async () => {
    setLoading(true);
    try {
      const url = `${baseUrl}/properties?page=${page}`;
      const { data } = await apiRequest(url, "GET", null, null);
      setProperies(data.properties);
      setTotalPages(data.totalPages);
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  const handlePageChange = (newPage) => {
    setSearchParams({ page: newPage });
  };

  return (
    <>
      <div className="container-fluid px-3" style={{ background: "white" }}>
        <title>Our Properties</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar properties={"active"} />
        <BackTop />

        {/*  Property List Start  */}
        <div className="container-xxl py-5">
          <div className="container">
            {/* header */}
            <div className="property-header text-center">
              <div
                className="mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px", margin: "0 auto" }}
              >
                <h1 className="mb-3">Our Properties</h1>
              </div>
            </div>

            <div className="row justify-content-center align-items-center g-0 gx-5">
              <div className="col-lg-12">
                <div
                  className="text-center mx-auto mb-5 wow slideInLeft"
                  data-wow-delay="0.1s"
                >
                  <h2 className="mb-3">Property Listing</h2>
                  <p>
                    Discover your dream home with our comprehensive Property
                    Listing.
                  </p>
                </div>
              </div>
            </div>

            <div className="tab-content">
              <div id="tab-1" className="tab-pane fade show p-0 active">
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50vh",
                    }}
                  >
                    <FadeLoader color="#0078e7" />
                  </div>
                ) : (
                  <div className="row g-4">
                    {propertyData?.length === 0 ? (
                      <div>
                        <h3 className="text-center">No Property Available.</h3>
                      </div>
                    ) : (
                      propertyData?.map((e, i) => {
                        return (
                          <>
                            <div
                              className="col-lg-4 col-md-6 wow fadeInUp"
                              data-wow-delay="0.1s"
                              key={i}
                            >
                              <Link to={`/property/${e.id}`}>
                                <div className="property-item rounded overflow-hidden">
                                  <div className="position-relative overflow-hidden img-fluid-fixed">
                                    <img
                                      className="img-fluid"
                                      src={e.images[0]}
                                      alt=""
                                    />
                                    <div className="bg-pri rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">
                                      {e.status}
                                    </div>
                                    <div className="bg-white rounded-top text-dark position-absolute start-0 bottom-0 mx-4 pt-1 px-3">
                                      {e.propertyType}
                                    </div>
                                  </div>
                                  <div className="p-4 pb-0">
                                    <h5 className="text-dark mb-3">
                                      {" "}
                                      &#8358;{e.price.toLocaleString()}
                                    </h5>
                                    <Link
                                      to={`/property/${e.id}`}
                                      className="d-block h5 title"
                                    >
                                      {e.title}
                                    </Link>
                                    <p style={{ minHeight: "70px" }}>
                                      <i className="fa fa-map-marker-alt text-dark me-2"></i>
                                      {e.address}
                                    </p>
                                  </div>
                                  <div className="d-flex border-top">
                                    <small className="flex-fill text-center border-end py-2">
                                      <i className="fa fa-ruler-combined text-dark me-2"></i>
                                      {e.propertySize}
                                    </small>
                                    <small className="flex-fill text-center border-end py-2">
                                      <i className="fa fa-bed text-dark me-2"></i>
                                      {e.bedrooms} Bedrooms
                                    </small>
                                    <small className="flex-fill text-center py-2">
                                      <i className="fa fa-bath text-dark me-2"></i>
                                      {e.toilet} Toilets
                                    </small>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </>
                        );
                      })
                    )}
                  </div>
                )}

                {/* Pagination */}
                {propertyData?.length > 0 ? (
                  <div>
                    <Pagination
                      className="my-5 wow slideInUp"
                      data-wow-delay="0.1s"
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Pagination.Prev
                        disabled={page === 1}
                        onClick={() => handlePageChange(page - 1)}
                      />

                      {/* Render page items and ellipses dynamically */}
                      {totalPages <= 10 ? (
                        // Render all page items if total pages are less than or equal to 10
                        Array.from({ length: totalPages }, (_, i) => (
                          <Pagination.Item
                            key={i + 1}
                            active={page === i + 1}
                            onClick={() => handlePageChange(i + 1)}
                          >
                            {i + 1}
                          </Pagination.Item>
                        ))
                      ) : (
                        <>
                          {/* Render first 5 page items */}
                          {Array.from(
                            { length: Math.min(5, totalPages) },
                            (_, i) => (
                              <Pagination.Item
                                key={i + 1}
                                active={page === i + 1}
                                onClick={() => handlePageChange(i + 1)}
                              >
                                {i + 1}
                              </Pagination.Item>
                            )
                          )}

                          {/* Ellipsis for hidden pages in the middle */}
                          <Pagination.Ellipsis />

                          {/* Render last 5 page items (or less if total pages are less than 10) */}
                          {Array.from(
                            { length: Math.min(5, totalPages) },
                            (_, i) => {
                              const adjustedIndex = totalPages - i;
                              return (
                                <Pagination.Item
                                  key={adjustedIndex}
                                  active={page === adjustedIndex}
                                  onClick={() =>
                                    handlePageChange(adjustedIndex)
                                  }
                                >
                                  {adjustedIndex}
                                </Pagination.Item>
                              );
                            }
                          )}
                        </>
                      )}

                      <Pagination.Next
                        disabled={page === totalPages}
                        onClick={() => handlePageChange(page + 1)}
                      />
                    </Pagination>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/*  Property List End  */}

        <div>
          <Footer />
        </div>
      </div>
      <GoToTop />
    </>
  );
}

export default Properties;
