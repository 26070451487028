import React from "react";
import NavBar from "../components/Nav";
import BackTop from "../components/BackToTop";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import img1 from "../assets/images/muyi.jpg";
import img13 from "../assets/images/dims.jpeg";
import img11 from "../assets/images/majesty.jpeg";
import img12 from "../assets/images/evelyn.jpeg";
import { Card } from "react-bootstrap";

function About() {
  return (
    <>
      <div classNameName="container-fluid px-3" style={{ background: "white" }}>
        <title>About RoyalMines Property</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar about={"active"} />
        <BackTop />

        {/* <!-- About Start --> */}
        <div className="container-xxl py-5">
          <div className="container">
            <div className="about-header text-center">
              <div
                className="mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px", margin: "0 auto" }}
              >
                <h1 className="mb-3">About Us</h1>
                <p>
                  A dedicated and diverse group of professionals united by a
                  passion for excellence. Get to know the individuals driving
                  our company forward, each bringing unique expertise and
                  perspectives to our collaborative efforts. Together, we strive
                  to deliver exceptional results and unparalleled service to our
                  clients.
                </p>
              </div>
            </div>

            {/* mission */}
            <div className="vision">
              <div
                className="text-center mx-auto mb-5 wow fadeInDown pt-5"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px" }}
              >
                <h2 className="mb-3">Our Mission</h2>
                <p>
                  Our mission is to lead Nigeria's real estate and mortgage
                  sector with trust and technology, delivering seamless
                  solutions and empowering dreams through comprehensive real
                  estate solutions.
                </p>
              </div>
            </div>
            <div className="hr" />
            {/* vision */}
            <div className="vision">
              <div
                className="text-center mx-auto mb-5 wow fadeInDown pt-5"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px" }}
              >
                <h2 className="mb-3">Our Vision</h2>
                <p>
                  To be the pioneering force, shaping Nigeria's real estate and
                  mortgage landscape through innovative technology and
                  unwavering integrity. We achieve this by driving
                  transformative solutions and prioritizing the aspirations of
                  all stakeholders, with a commitment to Professionalism,
                  Resilience, Empathy and Value creation.
                </p>
              </div>
            </div>

            <Card className="p-1 md:p-5 m-3">
              <section className="about-us p-3 md:p-5">
                <h2>Who We Are</h2>
                <div className="md:mx-4 mx-2">
                  <p>
                    Royalmines Property Limited was incorporated on the 10th day
                    of October 2014 as a Limited Liability company. The company
                    was principally incorporated to carry on the business as
                    developers of estate and property of any description. The
                    company has been at the centre of the real estate business
                    in the Federal Capital Territory. It has developed some
                    properties in the city of Abuja and is also into partnership
                    with some well-established real estate companies to provide
                    high quality and affordable properties for his numerous
                    clients.
                  </p>

                  <p>
                    Royalmine is blessed with several professionals with a
                    combined work experience of more than 100 years which
                    positioned the company as a strong emerging real estate
                    firm. We operate at Royalmines Property Limited by
                    systematically evaluating every detail of our client’s
                    interest. We have continually increased value and comfort of
                    our clients. We strive to exceed our customer’s expectation
                    by meeting their needs and also offer after sales service.
                  </p>

                  <p>
                    We also provide good and cut-edge refreshment services to
                    our clients at affordable prices. We run food delivery
                    services in an executive manner. Our staff are well trained
                    and they attend to the clients with high level of
                    professionalism and courtesy. We are highly committed to our
                    services which make our company to be topnotch.
                  </p>

                  <ul>
                    <p>
                      We have successfully carried out series of jobs from
                      various fields such as:
                    </p>
                    <div className="md:mx-5 mx-1">
                      <li>Construction of drainages</li>
                      <li>Building of shopping mall</li>
                      <li>Building of private residence</li>
                      <li>Building of estates</li>
                      <li>Rehabilitation Building </li>
                    </div>
                  </ul>
                </div>
              </section>

              <section
                className="services p-3 md:p-5"
                style={{ background: "whitesmoke", borderRadius: "10px" }}
              >
                <h2>Our Services</h2>
                <ul className="md:mx-3" style={{ listStyleType: "square" }}>
                  <p>Our services includes the following:</p>
                  <div className="md:mx-5">
                    <li> Real Estate</li>
                    <li>Information Communication Technology</li>
                    <li>Agro Allied</li>
                    <li>Food and Hospitality Management</li>
                    <li>Oil and Gas</li>
                    <li>Property Developer / Management</li>
                    <li>Construction/Civil work</li>
                    <li>Electrical Engineering and Rural Electrification</li>
                    <li>General Engineering Design and Consultancy</li>
                    <li>Financial Aid</li>
                    <li>Merchandise/General Contractor</li>
                  </div>
                </ul>
              </section>

              <section className="about-us p-3 md:p-5">
                <h2>Management Team</h2>
                <div className="md:mx-4 mx-2">
                  <p>
                    Royalmines Property Limited was incorporated on the 10th day
                    of October 2014 as a Limited Liability company. The company
                    was principally incorporated to carry on the business as
                    developers of estate and property of any description. The
                    company has been at the centre of the real estate business
                    in the Federal Capital Territory. It has developed some
                    properties in the city of Abuja and is also into partnership
                    with some well-established real estate companies to provide
                    high quality and affordable properties for his numerous
                    clients.
                  </p>

                  <p>
                    Royalmine is blessed with several professionals with a
                    combined work experience of more than 100 years which
                    positioned the company as a strong emerging real estate
                    firm. We operate at Royalmines Property Limited by
                    systematically evaluating every detail of our client’s
                    interest. We have continually increased value and comfort of
                    our clients. We strive to exceed our customer’s expectation
                    by meeting their needs and also offer after sales service.
                  </p>

                  <p>
                    We also provide good and cut-edge refreshment services to
                    our clients at affordable prices. We run food delivery
                    services in an executive manner. Our staff are well trained
                    and they attend to the clients with high level of
                    professionalism and courtesy. We are highly committed to our
                    services which make our company to be topnotch.
                  </p>

                  <ul>
                    <p>
                      We have successfully carried out series of jobs from
                      various fields such as:
                    </p>
                    <div className="md:mx-5 mx-1">
                      <li>Construction of drainages</li>
                      <li>Building of shopping mall</li>
                      <li>Building of private residence</li>
                      <li>Building of estates</li>
                      <li>Rehabilitation Building </li>
                    </div>
                  </ul>
                </div>
              </section>
            </Card>

            {/* team members */}
            <h2 className="my-3 text-center fs-1">Meet Our Team</h2>
            {/* board of Directors */}
            <div className="row g-4 team mt-5">
              <h3 className="mb-1 text-center fs-3">Board of Directors</h3>
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img11} alt="MAJESTY IWODI" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">MAJESTY IWODI</h5>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img13} alt="OLADIMEJI OGUN" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">OLADIMEJI OGUN</h5>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.7s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img1} alt="MUYIDEEN OYELEKE" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">MUYIDEEN OYELEKE</h5>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid" src={img12} alt="" />
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">EVELYN AKENBOR</h5>
                  </div>
                </div>
              </div>
            </div>

            {/* executive members */}
            <div className="row g-4 team mt-5 justify-content-center">
              <h3 className="mb-1 text-center fs-3">Executive Members</h3>
              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img11} alt="MAJESTY IWODI" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">MAJESTY IWODI</h5>
                    <small>MD/CEO</small>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img13} alt="OLADIMEJI OGUN" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">OLADIMEJI OGUN</h5>
                    <small>Director Finance & Admin</small>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-3 col-md-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="team-item rounded overflow-hidden">
                  <div className="position-relative">
                    <img className="img-fluid order-md-2" src={img1} alt="MUYIDEEN OYELEKE" />
                  </div>
                  <div className="text-center p-4 mt-3 order-md-1">
                    <h5 className="fw-bold mb-0">MUYIDEEN OYELEKE</h5>
                    <small>Director Business Development</small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- About End --> */}

        <div>
          <Footer />
        </div>
      </div>
      <GoToTop />
    </>
  );
}

export default About;
