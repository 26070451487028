import React from "react";
import img1 from "../assets/images/real.jpg";

function Service() {
  return (
    <>
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
              <div className="about-img position-relative overflow-hidden p-5 pe-0 bg-faint">
                <img
                  className="img-fluid w-100"
                  src={img1}
                  alt="real estate logo"
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
              <h1 className="mb-4">Our Services Include:</h1>
              <p className="mb-4">
                Welcome to our comprehensive real estate services. From property
                sales and mortgage assistance to construction projects, we
                provide tailored solutions to meet your every need.
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i>Comprehensive
                mortgage solutions tailored to your financial goals
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i>Expert guidance
                through every step of the construction process
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i>Receive expert
                guidance at every stage of the mortgage journey.
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i>Extensive property
                sales portfolio catering to diverse preferences
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i> Personalized
                assistance to find your dream home or investment property
              </p>
              <p>
                <i className="fa fa-check text-dark me-3"></i>Seamless
                transactions and transparent dealings throughout the process
              </p>
              {/* <button className="btn btn-primary py-3 px-5 mt-3">
                Read More
              </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
    </>
  );
}

export default Service;
