import React from "react";

function BackTop() {
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <>
      {/* <!-- Back to Top --> */}
      <button
        onClick={() => onTop()}
        className="btn btn-lg btn-primary btn-lg-square back-to-top fixed-bottom-right"
      >
        <p className="bi bi-arrow-up"></p>
      </button>
    </>
  );
}

export default BackTop;
