import React, { useContext, useEffect, useState } from "react";
import NavBar from "../components/Nav";
import BackTop from "../components/BackToTop";
import Footer from "../components/Footer";
import GoToTop from "../components/Top";
import { useParams } from "react-router-dom";
import { FaBed, FaBath, FaToilet,FaFile } from "react-icons/fa";
import { MdDining,MdChair } from "react-icons/md";
import FadeLoader from "react-spinners/FadeLoader";
import apiRequest from "../utils/apiRequest";
import { Store } from "../context/store";
import { Row, Col } from "react-bootstrap";

function Property() {
  let store = useContext(Store);
  let [baseUrl] = store.url;
  let { id } = useParams();
  const [property, setProperty] = useState(null);
  const [loading, setLoading] = useState(true);
  let [, setError] = useState("");

  useEffect(() => {
    Promise.all([loadProperty()])
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, [id]);

  const loadProperty = async () => {
    setLoading(true);
    try {
      const url = `${baseUrl}/property/${id}`;
      const { data } = await apiRequest(url, "GET", null, null);
      setProperty(data);
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <FadeLoader />
      </div>
    );
  }

  let address = property?.address;
  let videoUrl = property?.videos[0];
  const videoId = videoUrl?.split("=")[1];

  return (
    <>
      <div className="container-fluid px-3" style={{ background: "white" }}>
        <title>{property?.title}</title>
        <meta
          name="description"
          content="Find the best real estate and properties in Abuja and Nigeria. Explore a wide range of residential and commercial properties, including houses, apartments, land, and more. Discover trusted real estate agents, listings, and resources to help you buy, sell, or rent properties in Abuja and across Nigeria."
        />
        <meta
          name="keywords"
          content="real estate, properties, Abuja, Nigeria, houses, apartments, land, mortgages, mortgage, real estate agents, listings, buy, sell, rent, residential, commercial, apartments, houses, land, real estate solutions, real estate company"
        />

        <NavBar />
        <BackTop />

        {/*  Property List Start  */}
        <div className="container-xxl py-5">
          <div className="container">
            {/* header */}
            <div className="property-header text-center">
              <div
                className="mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: "600px", margin: "0 auto" }}
              >
                <h1 className="mb-3">{property?.title}</h1>
                <h4 className="mb-3 text-white">{property?.propertyType}</h4>
                <h4 className="mb-3 text-white">{property?.status}</h4>
              </div>
            </div>

            <div className="container">
              <div
                className="photo-gallery wow fadeInRightBig"
                data-wow-delay="0.1s"
              ></div>
              {/* Details */}
              <section
                className="property-details wow fadeInUp"
                data-wow-delay="0.1s"
              >
                <div className="details-container">
                  <div className="address">
                    <h3>Price: &#8358; {property?.price.toLocaleString()}</h3>
                    <h2>Address</h2>
                    <p>{property?.address}</p>
                  </div>

                  <div className="address"></div>

                  <Row
                    style={{
                      height: "fit-content",
                      justifyContent: "space-between",
                      rowGap: "20px",
                    }}
                  >
                    <Col md="6">
                      {/* main details-container */}
                      <div className="details" style={{ height: "100%" }}>
                        <h2>Details</h2>
                        <p className="">
                          <i className="fa fa-ruler-combined text-dark me-2"></i>
                          Size: {property?.propertySize}
                        </p>
                        <p>
                          <MdChair className="iconw" /> Sitting Room:{" "}
                          {property?.sittingRoom}
                        </p>
                        <p>
                          <FaBed className="iconw" /> Bedrooms:{" "}
                          {property?.bedrooms}
                        </p>
                        <p>
                          <FaBath className="iconw" /> Bathrooms:{" "}
                          {property?.bathroom}
                        </p>
                        <p>
                          <FaToilet className="iconw" />
                          Toilets: {property?.toilet}
                        </p>
                        <p>
                          <MdDining className="iconw" />
                          Dining Area: {property?.dining}
                        </p>
                        <p>
                          <FaFile className="iconw" />
                          Document Type: {property?.documentType}
                        </p>
                      </div>
                    </Col>
                    <Col md="6">
                      {/* Key Features */}
                      <section
                        className="key-features details wow fadeInLeftBig"
                        data-wow-delay="0.1s"
                        style={{ height: "100%" }}
                      >
                        <h2>Key Features</h2>
                        <ol>
                          {property?.features.map((e, i) => {
                            return (
                              <li
                                style={{ textTransform: "capitalize" }}
                                key={i}
                                className="mb-3"
                              >
                                {e}
                              </li>
                            );
                          })}
                        </ol>
                      </section>
                    </Col>
                  </Row>
                </div>
              </section>

              {/* Photo Gallery Section */}
              <section
                className="photo-gallery wow fadeInRightBig"
                data-wow-delay="0.1s"
              >
                {/* Finished building images */}
                <div className="finished-images">
                  <h2>Finished Building</h2>
                  {property?.images?.map((e, i) => {
                    return <img key={i} src={e} alt="Finished Building" />;
                  })}
                </div>
                {/* Plan images */}
                {/* <div className="plan-images">
                  <h2>Plans</h2>
                  <img
                    src="https://www.nethouseplans.com/wp-content/uploads/2019/09/Simple-3-Room-House-Plan-Pictures_4-Bedroom-House-Plan-Pictures_Nethouseplans-13.jpg"
                    alt="Plan"
                  />
                  <img
                    src="https://wpmedia.roomsketcher.com/content/uploads/2022/01/05101816/RoomSketcher-Custom-2D-Floor-Plan-Branding.jpg"
                    alt="Plan"
                  />
                  <img
                    src="https://www.nethouseplans.com/wp-content/uploads/2019/09/Simple-3-Room-House-Plan-Pictures_4-Bedroom-House-Plan-Pictures_Nethouseplans-13.jpg"
                    alt="Plan"
                  />
                  <img
                    src="https://wpmedia.roomsketcher.com/content/uploads/2022/01/05101816/RoomSketcher-Custom-2D-Floor-Plan-Branding.jpg"
                    alt="Plan"
                  />
                </div> */}
              </section>

              {/* Location & Amenities Section */}
              <section className="location-amenities">
                <h2>Location & Amenities</h2>
                <iframe
                  className="position-relative rounded w-100 h-100"
                  src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3963.9184595480395!2d7.500543615212516!3d9.058560193426446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s${encodeURIComponent(
                    address
                  )}!2s!5e0!3m2!1sen!2sng!4v1648449214373!5m2!1sen!2sng`}
                  frameborder="0"
                  style={{ minHeight: "400px", border: "0" }}
                  allowfullscreen=""
                  aria-hidden="false"
                  tabindex="0"
                  title="i"
                ></iframe>
                <ul>
                  {property?.landmarks?.map((e, i) => {
                    return (
                      <li style={{ textTransform: "capitalize" }} key={i}>
                        {e}
                      </li>
                    );
                  })}
                </ul>
              </section>

              {/* Contact Section */}
              <section className="contact">
                <h2>Contact Us</h2>
                {/* Contact form or details */}
                <p>Email: info@example.com</p>
                <p>Phone: +234 123 456 789</p>
              </section>

              {/* Video Section */}
              {videoId && (
                <section
                  className="video wow fadeInRightBig"
                  data-wow-delay="0.1s"
                >
                  <h2>Video Tour</h2>
                  <iframe
                    width="560"
                    height="315"
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&playlist=${videoId}&rel=0`}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </section>
              )}

              {/* Additional Information Section */}
              {/* <section className="additional-info">
                <h2>Additional Information</h2>
                <p>Pet-Friendliness: Yes</p>
                <p>Building Amenities: Gym, Pool, Rooftop Deck</p>
                <p>Garage/Parking: Yes</p>
              </section> */}
            </div>
          </div>
        </div>
        {/*  Property List End  */}

        <div>
          <Footer />
        </div>
      </div>
      <GoToTop />
    </>
  );
}

export default Property;
